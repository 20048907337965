.admin-wrapper {
  position: relative;
  top: 0;
  height: 100vh;
  background-color:#1B1B1B;
  font-family: 'Oswald', sans-serif;
}

.admin-main-panel {
  position: relative;
  float: right;
  width: calc(100% - 260px);
  background-color: #1B1B1B;
  color: #fff;
}

.admin-main-panel h1 {
  margin-bottom: 46px;
  text-align: center;
  margin-top: 36px;
  text-transform: uppercase;
  font-size: 5em;
  color: #fff;
  text-shadow: 6px 6px 0px rgba(0,0,0,0.75);
}

.admin-main-panel h2 {
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.7em;
  font-weight: bold;
  color: #fff;
  text-shadow: 6px 6px 0px rgba(0,0,0,0.75);
}

.admin-grid-container {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  grid-auto-rows: minmax(100px, auto);
  grid-auto-flow: row;
  background-color: #1B1B1B;
  color: #444;
}

.admin-grid-container.admin-home {
  grid-auto-rows: minmax(100px, 135px);
}

.admin-grid-container.two-columns {
  grid-template-columns: repeat(2, minmax(200px, 1fr));
}

.admin-grid-container.three-columns {
  grid-template-columns: repeat(3, minmax(100px, 1fr));
}

.question-box {
  border: 1px solid #000;
  background-color: #eee;
  color: #000;
  padding: 20px;
  border-radius: 4px;
  /* margin-bottom: 50px; */
  margin-bottom: 15px;
  text-align: center;
}

.question-box.mar-b-50 {
  margin: 0 auto 50px;
}

.question-box.mar-b-30 {
  margin-bottom: 30px !important;
}

.question-form {
  margin-bottom: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.question-box-current {
  border: 2px dashed #898989;
}

.question-box ul, .question-box ol {
  padding: 0;
  margin: 15px 0;
}

.question-box li {
  list-style-type: none;
  background: #fff;
  padding: 5px 10px;
  margin: 5px 0;
  border: 1px solid #000;
  border-radius: 4px;
  color: #000;
  text-align: left;
  font-weight: 500;
}

@media (min-width: 1200px) {
  .admin-grid-container {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }
  .admin-grid-container.admin-home {
    max-width: 700px;
    grid-template-columns: repeat(1, minmax(200px, 1fr));
    margin: auto;
  }
}

@media (max-width: 767px) {
  .admin-grid-container, .admin-grid-container.two-columns {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
}

.question-img {
  margin: 15px 0 !important;
}

.question-img img {
  border: 1px solid #000;
  min-height: 150px;
  max-height: 150px;
}


.btn.btn-default.btn-admin {
  margin: 5px 5px 5px 0;
  background: linear-gradient(#292929, #242424);
  border-top: 1px solid rgba(255,255,255,0.1);
  border-bottom: 1px solid black;
  text-decoration: none;
  /* height: 100%; */
  line-height: 35px;
  font-size: 1.3rem;
  color: #bbb;
  text-transform: uppercase;
  font-weight: bold;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  padding: 2px 15px;
}

.btn.btn-default.btn-admin:hover {
  cursor: pointer;
  color: #fff;
}

.btn-admin:not([disabled]):not(.disabled):active, .btn-admin:not([disabled]):not(.disabled).active {
  background: black;
}

.question-box label, .question-box p {
  font-weight: 500;
  margin: 0;
}

.question-hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.lines-on-sides {
  display: table;
  text-align: center;
  white-space: nowrap;
}
.lines-on-sides:after, .lines-on-sides:before {
  content: '';
  display: table-cell;
  width: 50%;
  background: -webkit-linear-gradient(transparent 50%, #fff 50%, #fff calc(50% + 1px), transparent calc(50% + 1px));
  background: linear-gradient(transparent 50%, #fff 50%, #fff calc(50% + 2px), transparent calc(50% + 2px));
  background-clip: padding;
  padding: 0 2px;
}
.lines-on-sides:after {
  border-left: 1em solid transparent;
}
.lines-on-sides:before {
  border-right: 1em solid transparent;
}

.carousel-img {
  max-width: 200px;
}

.modal-dialog {
  max-width: 767px !important;
}

.btn.btn-default.btn-admin.btn-create-question {
  margin: 0 auto 50px;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  font-size: 22px;
  background: #FA023C;
  border-bottom: none;
  color: #fff;
}

.btn.btn-default.btn-admin.btn-create-question:hover {
  color: #bbb;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
}

.btn-secondary:not([disabled]):not(.disabled):active {
  border-color: transparent !important;
}

.company-title {
  font-size: 1.5em;
  text-transform: uppercase;
}

.btn.btn-delete-option {
  margin-top: 7px;
  font-weight: bold;
}

.btn.btn-add-answer-option {
  float: left;
  bottom: 43px;
  position: relative;
  font-weight: bold;
}

.btn.btn-current-session {
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 1.5em;
  padding: 7px 20px;
}

.current-session {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

h4.header-stat {
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #000;
}

p.stat {
  text-align: center;
  font-weight: bold;

}

p.stat > span {
  background: #fff;
  padding: 2px 10px;
  border: 1px solid #000;
  color: #FA023C;
  border-radius: 4px;
  font-size: 25px;
}




/* alert */

#modalContainer {
	background-color:rgba(0, 0, 0, 0.3);
	position:absolute;
	width:100%;
	height:100%;
	top:0px;
	left:0px;
	z-index:10000;
	/* background-image:url(tp.png); required by MSIE to prevent actions on lower z-index elements */
}

#alertBox {
	position:relative;
  width: 400px;
  margin-top: 100px;
  border: 1px solid #666;
  border-radius: 4px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 20px 30px;
  padding: 25px;
}

#modalContainer > #alertBox {
	position:fixed;
}

#alertBox h1 {
	margin:0;
	font:bold 0.9em verdana,arial;
	background-color:#3073BB;
	color:#FFF;
	border-bottom:1px solid #000;
  padding:2px 0 2px 5px;
  display: none;
}

#alertBox p {
  text-align: center;
  margin-top: 0;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}

#alertBox #closeBtn {
	display:block;
	position:relative;
	margin:5px auto;
	padding:8px;
	border:0 none;
	width:90px;
	text-transform:uppercase;
	text-align:center;
	color:#FFF;
	background-color:#FA023C;
	border-radius: 3px;
  text-decoration:none;
  font-weight: 500;
}

#alertBox #closeBtn:focus {
  outline: none;
}

#alertBox #closeBtn:hover {
  color: #bbb;
}
