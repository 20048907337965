/*@import url('https://fonts.googleapis.com/css?family=Oswald:300,600&display=swap');*/
.admin-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 263px;
    display: block;
    z-index: 1;
    background: #000;
  }
/*
  .admin-sidebar:before, .admin-sidebar:after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  } */

  .admin-sidebar .sidebar-wrapper {
    position: relative;
    height: calc(100vh - 75px);
    overflow: auto;
    width: 260px;
    z-index: 4;
    padding-bottom: 30px;
  }

  .admin-sidebar .nav {
    margin-top: 20px;
  }

  .admin-sidebar .logo {
    position: relative;
    z-index: 4;
    padding: 10px 30px;
    border-bottom: 1px solid hsla(0,0%,100%,.2);
  }

  .admin-sidebar .logo a.logo-mini {
    float: left;
    text-align: center;
    width: 30px;
    margin-right: 15px;
  }

  .admin-sidebar a:hover, .admin-sidebar a:active, .admin-sidebar a:focus {
    text-decoration: none;
    color: #fff;
  }

  .admin-sidebar .logo .simple-text {
    text-transform: uppercase;
    padding: 5px 0;
    display: block;
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    line-height: 30px;
  }

  .admin-sidebar .logo .logo-img {
    width: 34px;
    display: inline-block;
    height: 34px;
    margin-left: -2px;
    margin-top: -2px;
    margin-right: 10px;
    border-radius: 30px;
    text-align: center;
  }

  .admin-sidebar .logo a.logo-mini img {
    width: 35px;
    margin-left: -3px;
    display: block;
    margin-top: 0px;
  }

  .sidebar-wrapper .nav-container {
    position: relative;
    height: calc(100vh - 75px);
    overflow: auto;
    width: 260px;
    z-index: 4;
    padding-bottom: 30px;
  }

    h1{
      text-align:center;
      letter-spacing:1px;
      text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
    }
    .nav-container{
      width:260px;
      margin-top:0;
      box-shadow: 0 2px 2px 2px black;
      transition: all 0.3s linear;
    }
    .nav-container .nav{
      list-style-type:none;
      margin:0;
      padding:0;
      display:block;
    }
    .nav-container li{
      height:50px;
      position:relative;
      background: linear-gradient(#292929, #242424);
    }
    .nav-container a {
      border-top: 1px solid rgba(255,255,255,0.1);
      border-bottom: 1px solid black;
      text-decoration:none;
      display:block;
      height:100%;
      width:100%;
      line-height:50px;
      color:#bbb;
      text-transform: uppercase;
      font-weight: normal;
      padding-left:25%;
      border-left:5px solid transparent;
      letter-spacing:1px;
      transition: all 0.3s linear;
    }
    .nav-container .active a{
      color: #B93632;
      border-left:5px solid #B93632;
      background-color: #1B1B1B;
      outline:0;
    }
    .nav-container li:not(.active):hover a{
      color: #eee;
      border-left: 5px solid #FCFCFC;
      background-color: #1B1B1B;
    }
    .nav-container span[class ^= "fa"]{
      position:absolute;
      left:20px;
      top: 13px;
      font-size: 1.5em;
      transition: all 0.3s linear;
    }
  /*
    @media only screen and (max-width : 860px){

      .nav-container .text{
        display:none;
      }

      .nav-container , a{
        width: 70px;

      }

      .nav-container a:hover{
        width:200px;
        z-index:1;
        border-top: 1px solid rgba(255,255,255,0.1);
        border-bottom: 1px solid black;
        box-shadow: 0 0 1px 1px black;
      }

      .nav-container a:hover  .text {
        display:block;
        padding-left:30%;
      }
    }
    @media only screen and (max-width : 480px){
      .nav-container, a{ width:50px;}
      .nav-container span[class ^= "icon"]{ left:8px;}
    } */
